html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./icons/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("./icons/MaterialIcons-Regular.woff2") format("woff2"),
    url("./icons/MaterialIcons-Regular.woff") format("woff"),
    url("./icons/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

/* roboto-latin-100-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"),
    url("./fonts/roboto-latin-100-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-100-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-100-italic.ttf") format("truetype");
}
/* roboto-latin-100-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url("./fonts/roboto-latin-100-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-100-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-100-normal.ttf") format("truetype");
}
/* roboto-latin-300-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
    url("./fonts/roboto-latin-300-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-300-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-300-italic.ttf") format("truetype");
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("./fonts/roboto-latin-300-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-300-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-300-normal.ttf") format("truetype");
}
/* roboto-latin-400-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url("./fonts/roboto-latin-400-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-400-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-400-italic.ttf") format("truetype");
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./fonts/roboto-latin-400-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-400-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-400-normal.ttf") format("truetype");
}
/* roboto-latin-500-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
    url("./fonts/roboto-latin-500-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-500-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-500-italic.ttf") format("truetype");
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("./fonts/roboto-latin-500-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-500-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-500-normal.ttf") format("truetype");
}
/* roboto-latin-700-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
    url("./fonts/roboto-latin-700-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-700-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-700-italic.ttf") format("truetype");
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./fonts/roboto-latin-700-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-700-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-700-normal.ttf") format("truetype");
}
/* roboto-latin-900-italic*/
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
    url("./fonts/roboto-latin-900-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-900-italic.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-900-italic.ttf") format("truetype");
}
/* roboto-latin-900-normal*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("./fonts/roboto-latin-900-normal.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/roboto-latin-900-normal.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      url("./fonts/roboto-latin-900-normal.ttf") format("truetype");
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	margin: 0;
	padding: 0;
	font-family: Roboto, Lato, Lucida Grande, Tahoma, Sans-Serif;
  overflow: hidden;
}