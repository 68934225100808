html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  src: url("MaterialIcons-Regular.d6f80c2b.eot");
  src: local(Material Icons), local(MaterialIcons-Regular), url("MaterialIcons-Regular.3ec75663.woff2") format("woff2"), url("MaterialIcons-Regular.1f17b367.woff") format("woff"), url("MaterialIcons-Regular.4a5adb0d.ttf") format("truetype");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin Italic), local(Roboto-ThinItalic), url("roboto-latin-100-italic.a1931ae4.woff2") format("woff2"), url("roboto-latin-100-italic.f980b363.woff") format("woff"), url("roboto-latin-100-italic.bda6baf2.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin), local(Roboto-Thin), url("roboto-latin-100-normal.401f3749.woff2") format("woff2"), url("roboto-latin-100-normal.28789877.woff") format("woff"), url("roboto-latin-100-normal.391b4636.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light Italic), local(Roboto-LightItalic), url("roboto-latin-300-italic.4ad36a98.woff2") format("woff2"), url("roboto-latin-300-italic.470fc714.woff") format("woff"), url("roboto-latin-300-italic.83890df2.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light), local(Roboto-Light), url("roboto-latin-300-normal.81a5bc04.woff2") format("woff2"), url("roboto-latin-300-normal.9527f5b1.woff") format("woff"), url("roboto-latin-300-normal.66068a26.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto Italic), local(Roboto-Italic), url("roboto-latin-400-italic.726359d1.woff2") format("woff2"), url("roboto-latin-400-italic.876e597b.woff") format("woff"), url("roboto-latin-400-italic.e5d7d043.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto), local(Roboto-Regular), url("roboto-latin-400-normal.962f1284.woff2") format("woff2"), url("roboto-latin-400-normal.541b858a.woff") format("woff"), url("roboto-latin-400-normal.76114e5c.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium Italic), local(Roboto-MediumItalic), url("roboto-latin-500-italic.21775980.woff2") format("woff2"), url("roboto-latin-500-italic.605cdec2.woff") format("woff"), url("roboto-latin-500-italic.fedd8d47.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium), local(Roboto-Medium), url("roboto-latin-500-normal.b3c683d1.woff2") format("woff2"), url("roboto-latin-500-normal.5f8bfa54.woff") format("woff"), url("roboto-latin-500-normal.aaffad30.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold Italic), local(Roboto-BoldItalic), url("roboto-latin-700-italic.00f6a7b9.woff2") format("woff2"), url("roboto-latin-700-italic.55756cd7.woff") format("woff"), url("roboto-latin-700-italic.d5d983c4.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold), local(Roboto-Bold), url("roboto-latin-700-normal.41e719aa.woff2") format("woff2"), url("roboto-latin-700-normal.f439c182.woff") format("woff"), url("roboto-latin-700-normal.002aa909.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black Italic), local(Roboto-BlackItalic), url("roboto-latin-900-italic.39f8a7c1.woff2") format("woff2"), url("roboto-latin-900-italic.fa7a4a2e.woff") format("woff"), url("roboto-latin-900-italic.d183ca97.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black), local(Roboto-Black), url("roboto-latin-900-normal.0f04a344.woff2") format("woff2"), url("roboto-latin-900-normal.c4cd6965.woff") format("woff"), url("roboto-latin-900-normal.a4bdfb5b.ttf") format("truetype");
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, Lato, Lucida Grande, Tahoma, sans-serif;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.d156119a.css.map */
